<template>
  <v-app-bar color="accent" max-height="8%">
    <v-toolbar-title><h2>María del Pilar Sánchez Castillejos</h2></v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="primary">
      <router-link to="/">
        <h2 style="font-weight: normal">Home</h2>
      </router-link>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary">
      <router-link to="/about">
        <h2 style="font-weight: normal">About me</h2>
      </router-link>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary">
      <h2 style="font-weight: normal">Projects</h2>
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="primary">
      <h2 style="font-weight: normal">Contact</h2>
    </v-btn>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <v-btn icon x-large>
      <v-icon color="primary">mdi-github</v-icon>
    </v-btn>
    <v-btn icon x-large>
      <v-icon color="primary">mdi-linkedin</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
name: "NavBar.vue"
}
</script>

<style scoped>
@import "../styles.css";
</style>