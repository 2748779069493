<template>
  <v-app :style="{background: $vuetify.theme.themes['light'].primary}">
    <NavBar></NavBar>
    <v-container></v-container>
    <v-main class="body">
      <v-row justify="center">
        <v-col align="center">
          <v-card color="accent" elevation="15" width="80%" rounded="xl">
            <v-toolbar color="accent"><v-spacer></v-spacer><h1>Hola!</h1><v-spacer></v-spacer></v-toolbar>
            <v-card-text><p>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
              dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
              deserunt mollit anim id est laborum.
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum."
            </p></v-card-text>
          </v-card>
        </v-col>
        <v-col align="center">
          <v-avatar color="accent" elevation="10" size="700" >
            <img src="../assets/ioverde.jpg" height="750" width="750" alt="sanchezpili6"/>
          </v-avatar>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/common_widgets/NavBar";
export default {
  name: "homeScreen",
  components:{
    NavBar
  }
}
</script>

<style scoped>
  @import "../styles.css";
</style>