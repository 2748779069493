import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#4ECDC4', // #E53935
                secondary:  '#654F6F',
                accent: '#1A535C'
            },
        },
    },
})